<template>
  
    <TheHeader />
    <router-view/>
  
</template>

<script>
import TheHeader from './components/TheHeader.vue'


export default {
  components: {
    TheHeader,
  },

}
</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0; 
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}



body{
  height: auto;
  background-color: #EFF3F9;
}

#app {
  background-color: #EFF3F9;
  height: auto;
  min-height: 100vh;
  //font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}





</style>
