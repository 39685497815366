<template>
  <base-title>Informações sobre o projeto</base-title>
  <InputTextArea
    label="Qual seu principal objetivo com este projeto?"
    name="objetivo"
    placeholder="Descreva o(s) seu(s) objetivo(s) com o projeto"
    @emit-input-text-value="getInputValue"
  />
  
  <InputText
    label="Indique ao menos 2 websites que vc considera uma referência: "
    name="referencia"
    placeholder="Sites que são referência"
    type="text"
    @emit-input-value="getInputValue"
  />

  <InputTextArea
    label="Qual produto, serviço ou aspecto do negócio você gostaria que o seu site enfatizasse?"
    name="destaques"
    placeholder="Descreva os produtos ou serviços que você gostaria de enfatizar"
    @emit-input-text-value="getInputValue"
  />

  <InputTextArea
    label="Qual o principal diferencial do seu negócio em relação aos concorrentes?"
    name="diferencial"
    placeholder="Descreva os seus pontos fortes em relação aos concorrentes."
    @emit-input-text-value="getInputValue"
  />

  <InputRadio 
    label="Você pretende que o seu site possua várias páginas ou apenas uma página?"
    name="type-of-site"
    :options="typeOfSiteOptions"
    @emit-selected-radio-value="getInputValue"
  />

  <InputRadio 
    label="Você pretende trabalhar com estratégias de conteúdo através do seu site (blog)?"
    name="content-strategy"
    :options="contentStrategyOptions"
    @emit-selected-radio-value="getInputValue"
  />

  <InputRadio 
    label="Você já possui as imagens e os textos do seu negócio que gostaria de utilizar no projeto?"
    name="business-media"
    :options="businessMediaOptions"
    @emit-selected-radio-value="getInputValue"
  />

  <InputCheckbox 
    question="Quais sessões e funcionalidades você já tem em mente para o desenvolvimento do seu site?"
    name="site-sections"
    :options="siteSectionOptions"
    @emit-selected-checkbox="getInputValue"
  /> 

    <InputTextArea
      label="No espaço abaixo, faça quaisquer outras observações que considerar pertinentes para o desenvolvimento do projeto:"
      name="customer-notes"
      placeholder="Insira aqui suas observações"
      @emit-input-text-value="getInputValue"
  />

  <base-button @click="upDateGeneralState" > concluir </base-button>

</template>

<script>
import InputText from '../components/inputs/InputText.vue'
import InputRadio from '../components/inputs/InputRadio.vue'
import InputCheckbox from '../components/inputs/InputCheckbox.vue'
import InputTextArea from '../components/inputs/InputTextArea.vue'
import BaseButton from '../components/BaseButton.vue'



export default {
  components: {
    InputText,
    InputRadio,
    InputCheckbox,
    InputTextArea,
    BaseButton,
  }, 

  data(){
    return {
      typeOfSiteOptions: 
        ["Site institucional de página única", 
        "Site institucional de multiplas páginas", 
        "Landing Page",
        "Ainda não tenho certeza"
        ],

      contentStrategyOptions: [
        "Sim", 
        "Não", 
        "Futuramente"
      ],
      businessMediaOptions: [
        "Sim", 
        "Não"
      ], 
      siteSectionOptions: [
        "Institucional", 
        "Serviços / Produtos", 
        "Galeria de imagens", 
        "Formulário para contato", 
        "Redirecionamento para whatsapp",
        "Chat",
        "Captura Leads"
      ],
      projectData: {
        goals: '',
        references: '', 
        features: '',
        differential: '',
        siteType: '', 
        contentStrategy: '',
        media: '',
        sections: [], 
        finalNotes:''

      }
    }
  }, 
  methods: {
    getInputValue(inputValue, inputName){
      if(inputName === 'objetivo'){
          this.projectData.goals = inputValue
      }
      if(inputName === 'referencia'){
        this.projectData.references = inputValue
        
      }
      if(inputName === 'destaques'){
        this.projectData.features = inputValue
        
      }
      if(inputName === 'diferencial'){
        this.projectData.differential = inputValue
        
      }
      if(inputName === 'type-of-site'){
        this.projectData.siteType = inputValue
        
      }
      if(inputName === 'content-strategy'){
        this.projectData.contentStrategy = inputValue
        
      }
      if(inputName === 'business-media'){
        this.projectData.media = inputValue
        
      }
      if(inputName === 'site-sections'){
        this.projectData.sections = inputValue
      }
      if(inputName === 'customer-notes'){
        this.projectData.finalNotes = inputValue
      }
    }, 

    upDateGeneralState(){
      this.$store.commit('getProjectData', this.projectData)
      this.$router.push('/review')

    }
  }
}
</script>

<style>

</style>