<template>
    <section class="content">
        <base-info-component label="Objetivos com o projeto" :value="project.goals" ></base-info-component> 
        <base-info-component label="Referências" :value="project.references" ></base-info-component> 
        <base-info-component label="Aspectos ou pontos fortes a serem destacados" :value="project.features" ></base-info-component> 
        <base-info-component label="Principais diferenciais do negócio em relação aos concorrentes" :value="project.differential" ></base-info-component> 
        <base-info-component label="Tipo do site" :value="project.siteType" ></base-info-component> 
        <base-info-component label="Irá trabalhar com estratégias de conteúdo no site" :value="project.contentStrategy" ></base-info-component> 
        <base-info-component label="Possui imagens e demais conteudos" :value="project.media" ></base-info-component> 

        
        
        <base-info-component-list 
            label="Seções e funcionalidades que o site deverá conter" 
            :values="project.sections">
        </base-info-component-list>


        <base-info-component label="Outras observações" :value="project.finalNotes" ></base-info-component> 
    </section>
</template>

<script>
export default {
    computed: {
        project(){
            return this.$store.getters.projectData
        }
    }
}
</script>

<style scoped lang="scss">

</style>