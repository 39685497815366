<template>
    <div class="why">
        <h2>Por quê responder o briefing?</h2>
        <p>O briefing é uma das etapas mais importantes para o desenvolvimento de qualquer projeto. Ele serve para orientar todas as decisões que serão tomadas durante o processo de desenvolvimento de uma aplicação, site institucional ou landing page. </p>
        <p>Procurei deixá-lo bem completo para que o projeto seja o mais assertivo possível. Então separe um café e 5 minutinhos do seu dia para respondê-lo com calma :) </p>
        <p>É importante ressaltar que todas as informações cedidas <strong> são confidenciais </strong>  e que <strong> nenhuma delas é obrigatória. </strong>Entretanto, respondê-lo corretamente e de forma completa é fundamental para o sucesso do projeto. </p>

        <p>
            Para começar, <strong class="start" @click="startAnswer">  clique aqui! </strong> 
        </p>

        <p>
            E em caso de dúvidas, <strong class="contact"> <a href="https://api.whatsapp.com/send?phone=553897487061&text=Ol%C3%A1!" target="_blank">clique aqui</a></strong>  para entrar em contato. 
        </p>


    </div>
</template>

<script>
export default {
    methods: {
        startAnswer(){
            this.$router.push('/')
        }
    }
}
</script>

<style lang="scss" scoped>

.why{
    background-color: #fff;
    max-width: 1200px;
    width: 80%;
    max-height: 1000px;
    height: 80%;
    margin-top: 100px;
    border-radius: 8px;
    box-shadow: 1px 3px 20px rgba(33, 36, 39, 0.123);
    display: flex;
    flex-direction: column;
    
    padding: 50px;
    
    h2{
        color: #3947a3;
        margin-bottom: 40px;
        text-align: left;
        font-weight: 700;
    }

    p{
        width: 90%;
        margin-bottom: 15px;
        line-height: 160%;
        color: #373d47; 
        font-weight: 400;
        font-size: 1.1rem;

        strong{
            font-weight: 600;
        }
    }

    .contact{
        color: #3947a3;
        cursor: pointer;
        transition: .1s;
        font-weight: 700;
        
            &:hover{
            color: #FE2C6F;
            font-size: 1.15rem;
        }
    }

    .start{
        color: #3947a3;
        cursor: pointer;
        transition: .1s;
        font-weight: 700;

        &:hover{
            color: #263175;
            font-size: 1.15rem;
        }
    }
}

</style>>
