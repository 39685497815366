<template>
<div>
  <InfoContainer />
</div>
</template>

<script>
  import InfoContainer from '../components/InfoContainer.vue'

  export default{
    components: {
      InfoContainer
    }
  }
</script>


<style scoped lang="scss" > 
div{
    width: 100%;
    height: calc(100vh - 60px) ;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>