import { createRouter, createWebHistory } from 'vue-router'

import Answer from '../views/Answer.vue'
import WhyAnswer from '../views/WhyAnswer.vue'

import GeneralInformation from '../views/GeneralInformation'
import BusinessInformation from '../views/BusinessInformation'
import ProjectInformation from '../views/ProjectInformation'
import DataReview from '../views/DataReview'
import Thanks from '../components/Thanks'

const routes = [
  {
    path: '/',
    name: 'Answer',
    component: Answer, 
    children: [
      {
        path: '/',
        name: 'General',
        component: GeneralInformation, 
      }, 
      {
        path: '/business',
        name: 'Business',
        component: BusinessInformation, 
      }, 
      {
        path: '/project',
        name: 'Project',
        component: ProjectInformation, 
      }, 
      {
        path: '/thank-you',
        name: 'Thanks',
        component: Thanks, 
      }
    ]
  },
  {
    path: '/why-answer',
    name: 'WhyAnswer',
    component: WhyAnswer
  }, 
  {
    path: '/review',
    name: 'DataReview',
    component: DataReview
  }, 

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
