import { createStore } from 'vuex'

export default createStore({
  state: {
    customer:{
      generalInformation: {
        name: '',
        business: '', 
        email: ''
      }, 
      businessInformation: {
        year: 2022, 
        segment: '', 
        hasSite: null, 
        siteUrl: '', 
        businessDiscovery: null, 
        region: '', 
        productsServices: '', 
        positioning: '',
        competitors: '',         
        challenges: '',
        target: ''

      }, 
      projectInformation: {
        goals: '',
        references: '', 
        features: '',
        differential: '',
        siteType: '', 
        contentStrategy: '',
        media: '',
        sections: [], 
        finalNotes:''
      }
    }
  },

  mutations: {
    getGeneralData(state, payload){
      let generalData = state.customer.generalInformation

      generalData.name =  payload.customerName
      generalData.business = payload.businessName
      generalData.email = payload.customerEmail

      console.log("STORE: " + state.customer.generalInformation.name)
    }, 
    getBusinessData(state, payload){
      let businessData = state.customer.businessInformation

      businessData.year = payload.ano
      businessData.segment = payload.segmento
      businessData.hasSite = payload.possuiSite 
      businessData.siteUrl = payload.urlSite
      businessData.businessDiscovery = payload.descoberta
      businessData.region = payload.regiao
      businessData.productsServices = payload.productService
      businessData.positioning = payload.positioning 
      businessData.competitors = payload.competitors
      businessData.challenges = payload.challenge
      businessData.target = payload.target
      console.log(businessData)
    },
    getProjectData(state, payload){
      let projectData = state.customer.projectInformation
      
      projectData.goals = payload.goals
      projectData.references = payload.references
      projectData.features = payload.features
      projectData.differential = payload.differential
      projectData.siteType = payload.siteType
      projectData.contentStrategy = payload.contentStrategy
      projectData.media = payload.media
      projectData.sections = payload.sections
      projectData.finalNotes =  payload.finalNotes
    }
  },

  actions: {



  },

  modules: {
  },
  getters: {
    generalData(state){
      return state.customer.generalInformation
    },
    businessData(state){
      return state.customer.businessInformation
    },
    projectData(state){
      return state.customer.projectInformation
    }, 
    allData(state){
      return state.customer
    }
  }
})
