<template>
    <div class="box-form">
        <FormNavigation/>
        <router-view/>
    </div>
</template>

<script>
import FormNavigation from './FormNavigation.vue'; 
export default {
components: {
    FormNavigation
},
}
</script>

<style>

    .box-form{
        background-color: #EBEEF2 ;
        border-radius: 5px;
        box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.15);
        border-bottom: 6px solid #FE2C6F;
        width: 60%;
        margin-top: 100px;
        max-width: 1000px;
        min-width: 250px;
        height: 80vh;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
    }

    @media only screen and (max-width: 1400px){
        .box-form{
            width: 80%;
        }
    }

    @media only screen and (max-width: 700px){
        .box-form{
            width: 90%;
        }
    }

::-webkit-scrollbar {
    width: 9px;
}

::-webkit-scrollbar-track {
    background-color: #a2afc9;
    border-radius: 10px;    
}

::-webkit-scrollbar-thumb {
    background: #3747B2; 
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #222C85;

}

</style>

