<template>
    <section class="content">
        <base-info-component label="nome" :value="general.name" ></base-info-component>
        <base-info-component label="empresa" :value="general.business"></base-info-component>
        <base-info-component label="email" :value="general.email"></base-info-component>
    </section>
</template>

<script>
export default {
    computed: {
        general(){
            return this.$store.getters.generalData
        }
    }
}
</script>

<style scoped lang="scss">

</style>