<template>
    <div class="form-control">
        <label :for="name">{{label}}</label>

        <select :name="name" id="" v-model="selectedValue" >
            <option 
                :value="option" 
                v-for="option in options" 
                :key="option"> 
                {{option}}
            </option>
        </select>


    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String, 
            required: true
        },
        name: {
            type: String, 
            required: true
        }, 
        options: {
            type: Array, 
            required: true
        }, 
    },
    data(){
        return{
            selectedValue: this.options[0]
        }
    },
    watch: {
        selectedValue(){
            this.$emit('emit-selected-value', this.selectedValue, this.name)
        }
    }
}
</script>

<style scoped lang="scss">
    .form-control{
        display: flex; 
        flex-direction: column;
        width: 85%;
        justify-content: center;
        align-items: flex-start;
        margin: 0 auto 30px auto;

        
    label{
        color: #212c3a;
        font-size: 1rem;
        font-weight: 500;
    }

    select{
        padding: 12px; 
        border-radius: 6px; 
        border: 1px solid #c0cfe0;
        margin: 10px 0; 
        cursor: pointer;
        transition: .1s;
        color: #3F4A57;
        font-size: 0.9rem;
        
    }

    select:focus{
        outline: none; 
        transform: scale(1.02);
        box-shadow: 2px 2px 14px rgba(111, 142, 155, 0.336);
        }
    }

    @media only screen and (max-width: 1400px){
        .form-control{
            select{
                padding: 9px; 
            }
        }
    }

</style>