<template>
  <div class="form-container">
    
      <TheFormContainer />
    
  </div>
</template>

<script>

import TheFormContainer from '../components/TheFormContainer.vue';

export default {
  name: 'Home',
  components: {
    TheFormContainer
  },
}
</script>
<style scoped>
  .form-container{
    width: 100%;
    height: calc(100vh - 60px) ;
    display: flex;
    justify-content: center;
    align-items: center;
  }

</style>