<template>
    <div class="title">
        <h2><slot/></h2>
        <hr>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .title{
        display: flex; 
        flex-direction: column;
        width: 85%;
        justify-content: center;
        align-items: flex-start;
        margin: 10px auto 0 auto;
        color: #39495a;
    }

    hr{
        width: 100%;
        margin: 20px 0; 
    }

    @media only screen and (max-width: 1400px){
        h2{
            font-size: 1.3rem;
        }
    }

    @media only screen and (max-width: 600px){
        h2{
            font-size: 1.2rem;
            letter-spacing: 0px;
        }
    }


</style>