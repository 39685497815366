<template>
    <div>
        <div>
            <h2>Por favor revise seus dados</h2>
            <div class="review">
                <base-title>1. Informações gerais</base-title>
                <GeneralInfo  />
                
            

                <base-title>2. Sobre o negócio</base-title>
                <BusinessInfo/>


                <base-title>3. Sobre o projeto</base-title>
                
                <ProjectInfo/>

                <base-button @click="sendData">Enviar</base-button>
                
            </div>
        </div>
    </div>  
</template>

<script>
import ProjectInfo from '../components/review/ProjectInfo.vue'
import GeneralInfo from '../components/review/GeneralInfo.vue'
import BusinessInfo from '../components/review/BusinessInfo.vue'
import BaseButton from '../components/BaseButton.vue'

export default {
    data(){
        return {
            finalData: null
        }
    },

    components:{
        ProjectInfo, 
        GeneralInfo, 
        BusinessInfo,
        BaseButton
    }, 
    methods: {
        sendData(){
            this.finalData = this.allInfo
            fetch('https://briefing-48b5a-default-rtdb.firebaseio.com/customers.json', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    briefing: this.finalData
                })
            }) 

            this.$router.push('/thank-you')
        }
    }, 
    computed: {
        allInfo(){
            return this.$store.getters.allData
        }
    }
}
</script>

<style lang="scss" >
.review{
    background-color: #fcfcfc; 
    width: 80%;
    max-width: 1000px;
    margin: 100px auto; 
    padding: 30px 0; 
    border-radius: 6px;
    box-shadow: 0 4px 20px rgba(38, 43, 48, 0.151);
    
    section{
        margin: 0 auto; 
        width: 85%;

        p{
            color: #474f5e;
            margin-bottom: 10px;
            font-family:  Arial, Helvetica, sans-serif
        }
    }
}

.content{
    background-color: #f3f4f7;
    padding: 30px; 
    border-radius: 8px;

}
</style>