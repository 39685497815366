<template>
    <div class="form-control">
        <p>
            {{label}}
        </p>
        <div
            v-for="option in options" 
            :key="option">
            <input 
                type="radio" 
                :name="name" 
                :value="option" 
                v-model="selectedValue">
            <label 
                :for="option">
                {{option}}
            </label>
        </div>
        
    </div>
</template>

<script>
export default {
    props:{
        name: {
            type: [Boolean, String], 
            required: true
        }, 
        options: {
            type: Array,
            required: true
        }, 
        label: {
            type: String, 
            required: true
        }
    },
    data(){
        return{
            selectedValue: ''
        }
    }, 
    watch: {
        selectedValue(){
            this.$emit('emit-selected-radio-value', this.selectedValue, this.name)
        }
    }
}
</script>

<style lang="scss" scoped>
    .form-control{
        display: flex; 
        flex-direction: column;
        width: 85%;
        justify-content: center;
        align-items: flex-start;
        margin: 0 auto 30px auto;
        color: #3F4A57;
        font-size: 1rem; 

        input{
            margin-right: 5px;
            margin-top: 3px;
        }

        div{
            display: flex;
            align-items: center;
            margin-bottom: 2px;
        }

        p{
            color: #212c3a;
            font-size: 1rem;
            font-weight: 500;
            margin-bottom: 5px;
        
        }
    }

</style>