<template>  
    <base-title>Informações do negócio</base-title>
    <InputSelectYear 
        name="ano"
        :label="labels.ano"
        @emit-selected-year-value="getSelectedYearValue"
    />

    <InputSelect 
        :label="labels.segmento" 
        name="segmento" 
        :options="segmentoOpcoes" 
        @emit-selected-value="getSelectedValue"
        />

    <InputRadio 
        name="site" 
        :label="labels.possuiSite"
        :options="possuiSiteOpcoes"
        @emit-selected-radio-value="getRadioValue"
        />

    <InputText 
        v-if="businessInformation.possuiSite === true"
        label="URL do site:"
        placeholder="Digite o seu site"
        name="url-site"
        type="text"
        @emit-input-value="getInputValue"
        />
    

    <InputCheckbox 
        name="descoberta" 
        :question="labels.descoberta" :options="descobertaOpcoes" 
        @emit-selected-checkbox="getCheckboxesValues"
        />

    
    <InputSelect 
        :label="labels.regiao" 
        name="regiao" 
        :options="regiaoOpcoes" 
        @emit-selected-value="getSelectedValue"
    />



    


    <InputTextArea 
        :label="labels.produtoServico"
        name="productService"
        @emit-input-text-value="getInputValue"
        />

    <InputTextArea 
        :label="labels.posicionamento"
        name="positioning"
        @emit-input-text-value="getInputValue"
        />

    <InputTextArea 
        :label="labels.concorrentes"
        name="competitors"
        @emit-input-text-value="getInputValue"
        />

    <InputTextArea 
        :label="labels.maiorDesafio"
        name="challenge"
        @emit-input-text-value="getInputValue"
        />

    <InputTextArea 
        :label="labels.target"
        name="target"
        @emit-input-text-value="getInputValue"
        />

    <base-button @click="upDateGeneralState">Próximo</base-button>
</template>

<script>
import InputText from '../components/inputs/InputText.vue'
import InputTextArea from '../components/inputs/InputTextArea.vue'
import InputSelect from '../components/inputs/InputSelect.vue'
import InputRadio from '../components/inputs/InputRadio.vue'
import InputCheckbox from '../components/inputs/InputCheckbox.vue'
import InputSelectYear from '../components/inputs/InputSelectYear.vue'


export default {
    components: {
        InputTextArea, 
        InputSelect,
        InputText,
        InputRadio, 
        InputCheckbox,
        InputSelectYear
    },
    data(){
        return {

            labels: {
                produtoServico:
                    "Quais os produtos ou serviços ofertados pela sua empresa?",
                posicionamento: 
                    "Como sua empresa se posiciona ou gostaria de se posicionar no mercado?", 
                concorrentes: "Quais são seus principais concorrentes diretos?",
                maiorDesafio: "Atualmente, qual o maior desafio do seu negócio?", 
                target: "Qual o público alvo do seu negócio?", 
                segmento: "Segmento da empresa:", 
                regiao: "Região de atuação",
                descoberta: "Atualmente, como o público tem descoberto seu negócio (marque todas as opções que se aplicarem) ?", 
                possuiSite: "Possui site?", 
                ano: "Quando a sua empresa iniciou suas atividades?"

            }, 

            businessInformation: {
                productService: '',
                positioning: '', 
                competitors: '',
                challenge: '', 
                target: '',
                segmento: '',
                regiao: '',
                possuiSite: '', 
                urlSite: '',
                descoberta: '',
                ano: 2022
            },

            segmentoOpcoes: [
                "Direito", "Saúde", "Engenharia Civil", 
                "Engenharia Química",  "Tecnologia da Informação", 
                "Propaganda, Publicidade e Marketing", "Educação", 
                "Varejo", "Indústria", "Recursos Humanos", "Pets / Petshop", 
                "Órgão Público", "Finanças",
                "Organização Não Governamental", 
                "Outro"
            ],
            regiaoOpcoes: [
                "Local / Municipal",   
                "Regional", 
                "Estadual", 
                "Nacional", 
                "Internacional"
                ],
            descobertaOpcoes: [
                "boca a boca", 
                "redes sociais (orgânico)", 
                "anúncios online", 
                "mídia impressa",
                "rádio", 
                "tv", 
                "outros"
                ],
            possuiSiteOpcoes: ["sim", "nao"]
        }
    },

    /* watch:{
        'labels.segmentoOpcoes'(){
            console.log('mudou')
        } 
    }, */

    methods:{
        getInputValue(inputValue, inputName){
            if(inputName === 'productService'){
                this.businessInformation.productService = inputValue
            }
            if(inputName === 'positioning'){
                this.businessInformation.positioning = inputValue
            }
            if(inputName === 'competitors'){
                this.businessInformation.competitors = inputValue
            }
            if(inputName === 'challenge'){
                this.businessInformation.challenge = inputValue
            }
            if(inputName === 'target'){
                this.businessInformation.target = inputValue
            } 
            if(inputName === 'url-site'){
                this.businessInformation.urlSite = inputValue
            }
        },
        getSelectedValue(selectedValue, name){
            console.log(selectedValue)  
            if(name === "segmento"){
                this.businessInformation.segmento = selectedValue
            }
            if(name === "regiao"){
                this.businessInformation.regiao = selectedValue
            }
        },
        getRadioValue(selectedValue, name){
            console.log(selectedValue, name)
            if(name === "site"){
                this.businessInformation.possuiSite = selectedValue === "sim" ? true : false
            }
            
        },
        getCheckboxesValues(selectedValue, name){
            if(name === "descoberta")
            this.businessInformation.descoberta = selectedValue
        },
        getSelectedYearValue(selectedValue){
            this.businessInformation.ano = selectedValue
        }, 

        upDateGeneralState(){
            this.$store.commit('getBusinessData', this.businessInformation)
            this.$router.push('/project')
        }

        

    },
    watch: {
        
    }, 
    computed: {
        
    }
}
</script>

<style>

</style>