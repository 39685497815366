<template>
    <base-section-title>Informações Gerais</base-section-title>

    <input-text 
        content=""
        label="Seu Nome" 
        type="text"
        name="name" 
        placeholder="insira seu nome"
        @emit-input-value="getInputValue"> 
    </input-text>

    <input-text 
        label="Nome do seu negócio" 
        type="text"
        name="business" 
        placeholder="insira o nome da sua empresa"
        @emit-input-value="getInputValue"> 
    </input-text>

    <input-text 
        label="Email:" 
        type="email"
        name="email" 
        placeholder="seunome@exemplo.com"
        @emit-input-value="getInputValue"> 
    </input-text>


    <base-button @click="upDateGeneralState">
        Próximo
    </base-button>

</template>

<script>
import BaseSectionTitle from '../components/BaseSectionTitle.vue'


export default {
    components: { BaseSectionTitle },

    

    data(){
        return{
            generalInformation: {
                customerName: '',
                businessName: '',
                customerEmail: ''
            }
        }
    }, 

    methods:{

        //get through event emitter the input value and the input name
        getInputValue(inputValue, inputName){
            
            //this.generalInformation.customerName = inputValue

            if(inputName === 'name'){
                this.generalInformation.customerName = inputValue
            }
            if(inputName === 'business'){
                this.generalInformation.businessName = inputValue
            }
            if(inputName === 'email'){
                this.generalInformation.customerEmail = inputValue
            }
            //console.log(this.generalInformation)
        }, 

        upDateGeneralState(){
            this.$store.commit('getGeneralData', this.generalInformation)
            this.$router.push('/business')
        }
    }
}
</script>

<style scoped>

</style>