<template>
    <div class="button-container">
        <button>
            <slot/>
        </button>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    .button-container{
        display: flex;
        flex-direction: column;
        width: 85%;
        margin: 30px auto 50px auto;
    }

    button{
        background-color: #3747B2;
        border-radius: 4px;
        border: none; 
        padding: 12px 45px;
        color: white; 
        display: block;
        width: 200px;
        transition: .2s;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 0.7rem;

        &:hover{
            background-color: #222C85;
        }

        


    }
</style>

