<template>
    <div>
        <strong>{{label}}: </strong> <br>
        <span v-for="value in values" :key="value"> ✔ {{value}}</span>        
    </div>

</template>

<script>

export default {
    props: ['label', 'values']
}
</script>

<style lang="scss" scoped>
    div{
        
        margin-bottom: 50px;

        strong{
            font-size: 1.1rem;
            color: #334a5e; 
        }
        span{
            display: block;
            margin-top: 7px;
            font-size: 1.1rem;
            color: #4B5073
        }
    }
</style>