<template>
    <div class="form-control">
        <label :for="name">{{label}}</label>
        <select :name="name" v-model="selectedValue" >
            <option 
                v-for="year in years" 
                :key="year"
                > 
                {{year}}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String, 
            required: true
        },
        name: {
            type: String, 
            required: true
        }
    },
    data(){
        return{
            selectedValue: 2022, 
            years: []
        }
    },
    watch: {
        selectedValue(){
            this.$emit('emit-selected-year-value', this.selectedValue)
        }
    }, 
    created(){
        for ( let i = 1920 ; i <= 2022 ; i++){
            this.years.unshift(i)
        }
    }
}
</script>

<style scoped lang="scss">
    .form-control{
        display: flex; 
        flex-direction: column;
        width: 85%;
        justify-content: center;
        align-items: flex-start;
        margin: 0 auto 30px;

        
    label{
        color: #212c3a;
        font-size: 1rem;
        font-weight: 500;
        
    }

    select{
        padding: 10px; 
        border-radius: 6px; 
        border: 1px solid #c0cfe0;
        margin: 10px 0; 
        cursor: pointer;
        transition: .1s;
        color: #3F4A57;
        font-size: 0.9rem;
        
    }

    select:focus{
        outline: none; 
        transform: scale(1.02);
        box-shadow: 2px 2px 14px rgba(141, 180, 196, 0.411);
        }
    }

    @media only screen and (max-width: 1400px){
        .form-control{
            select{
                padding: 9px; 
            }
        }
    }
</style>