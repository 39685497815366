<template>
    <section class="content">
        <base-info-component label="Início de atuação" :value="business.year" ></base-info-component>
        <base-info-component label="Segmento de atuação" :value="business.segment" ></base-info-component>


        <base-info-component 
            label="Possui site" 
            :value="hasSite" >
            </base-info-component>
        
        <base-info-component  
            v-if="business.hasSite" 
            label="URL do site" 
            :value="business.siteUrl" >
        </base-info-component>


        <base-info-component-list label="Como o negócio é descoberto" :values="business.businessDiscovery"></base-info-component-list>




        <base-info-component label="Região de atuação da empresa" :value="business.region" ></base-info-component>
        <base-info-component label="Produtos e/ou serviços oferecidos" :value="business.productsServices"></base-info-component>
        <base-info-component label="Como a empresa se posiciona ou gostaria de se posicionar" :value="business.positioning"></base-info-component>
        <base-info-component label="Principais concorrentes" :value="business.competitors" ></base-info-component>
        <base-info-component label="Maiores desafios do negócio" :value="business.challenges" ></base-info-component>
        <base-info-component label="Público alvo do negócio" :value="business.target" ></base-info-component>
    </section>
</template>

<script>
export default {
    computed: {
        business(){
            return this.$store.getters.businessData
        }, 

        hasSite(){
            return this.business.hasSite ? "Sim" : "Não"   
        }
    }
}
</script>

<style scoped lang="scss">

</style>