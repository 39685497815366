import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import InputRadio from './components/inputs/InputRadio.vue'
import InputSelect from './components/inputs/InputSelect.vue'
import InputTextArea from './components/inputs/InputTextArea.vue'
import InputText from './components/inputs/InputText.vue'
import BaseButton from './components/BaseButton.vue'
import BaseSectionTitle from './components/BaseSectionTitle.vue'
import InfoComponent from './components/InfoComponent.vue'
import InfoComponentList from './components/InfoComponentList.vue'

const app = createApp(App)

app.component('input-text', InputText)
app.component('input-radio', InputRadio)
app.component('input-select', InputSelect)
app.component('input-text-area', InputTextArea)
app.component('base-button', BaseButton)
app.component('base-title', BaseSectionTitle)
app.component('base-info-component', InfoComponent)
app.component('base-info-component-list', InfoComponentList)

app.use(store).use(router).mount('#app')

/* createApp(App).use(store).use(router).mount('#app') */