<template>
    <div class="msg-container">
        <h2>Muito obrigada!</h2>
        
        <p>Em breve entrarei em contato para conversarmos sobre o seu projeto! :)  </p>
        <p>Em caso de dúvidas ou novas ideias, me chame no whatsapp  <a href="https://api.whatsapp.com/send?phone=553897487061&text=Ol%C3%A1!"> clicando aqui. </a> </p>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">

    .msg-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2{
            color: #222C85;
            font-size: 1.6rem;
            margin-top: 30px;
            margin-bottom: 30px;
        }

        p{
            font-size: 1.2rem;
            margin-top: 10px;
        }

        a {
            font-weight: bold;
            transition: all .1s;

            &:hover{
                color: #BA074F; 
                font-size: 1.25rem;
            }
        }
    }

</style>