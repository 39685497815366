<template>
    <div class="form-control">
        
        <p>{{question}}</p>

        <div v-for="option in options" :key="option">

            <input 
                type="checkbox" 
                :name="name" 
                :value="option"
                v-model="selectedValue"
                >

            <label :for="option"> 
                {{option}}
            </label>
        </div>
        
    </div>
</template>

<script>
export default {
    props: {
        question: {
            type: String, 
            required: true
        }, 
        options: {
            type: Array, 
            required: true
        }, 
        name: {
            type: String,
            required: true
        }
    },
    data(){
        return{
            selectedValue: []
        }
    },
    watch: {
        selectedValue(){
            this.$emit('emit-selected-checkbox', this.selectedValue, this.name)
        }
    }
}
</script>

<style scoped lang="scss">

        .form-control{
            display: flex; 
            flex-direction: column;
            width: 85%;
            justify-content: center;
            align-items: flex-start;
            margin: 0 auto 20px auto;
            color: #3F4A57;
            font-size: 1rem; 

        p{
            margin-bottom: 8px;
            color: #212c3a;
            font-size: 1rem;
            font-weight: 500;
        }

        input {
            margin: 10px 5px 5px 0;
        }

        label{
            margin-top: 2px;
        }

        div{
            display: flex;
            align-items: center;
        }
    }

</style>