<template>
<header id="nav">
    <div class="logo"> 
        <a href="http://www.flaviaxavier.com" target="_blank">
            
            <img src="../assets/logo.svg" alt="" >
        </a>
    </div>
    <nav>
        <router-link to="/">Responder</router-link> | 
        <router-link to="/why-answer">Por quê responder?</router-link>
    </nav>
</header>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped >
#nav {
    background-color: #F0F4FA;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 50px;
    border-top: 7px solid #FE2C6F;
    box-shadow: 0px 4px 15px rgba(26, 33, 44, 0.075);
    position: fixed; 
    z-index: 6;
    width: 100%; 

    img{
        width: 140px;
    }

    a{
        font-weight: bold;
        color: #4d566b; 
    }
    a:active{
        color: #FE2C6F; 
    }
    a.router-link-active {
        color: #3747B2; 
    }
}

@media only screen and (max-width: 1400px) {
    #nav {
        height: 60px;
        font-size: 0.9rem;
        img{
            width: 120px;
            margin-top: 3px;
        }
    } 
}


@media only screen and (max-width: 800px){
    #nav {
        font-size: 0.8rem;
        height: 50px;
        img{
            width: 100px;
        }
    } 
}




</style>