<template>
    <nav>
        <ul>
            <li>
                <router-link  to="/"> 1. Informações Gerais </router-link> 
            </li>
            <li>
                <router-link to="/business">2. Sobre o Negócio</router-link>
            </li>
            <li>
                <router-link to="/project">3. Sobre o Projeto</router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    
}
</script>

<style scoped lang="scss">
    nav{
        background-color: #6170d1;
        border-radius: 7px 7px 0  0;
        
        color: #DFEAF9; 
        margin-bottom: 40px;    

        ul{
            display: flex;
            justify-content: space-around;
            padding-top: 15px;
            padding-bottom: 15px;
        }
        

        a{
            color: #fff;  
            font-size: 1.1rem;
            transition: .2s;
        }

        a:hover{
            
            color: #d8dbdd; 
            
            
        }
        a.router-link-exact-active {
            font-weight: bold;
            color: #151e6b; 
        }
    }

    @media only screen and (max-width: 1400px){
        nav{
            ul{
                padding-top: 10px; 
                padding-bottom: 10px;

                a{
                    font-size: 1rem;
                }
            }
        }
    }

    @media only screen and (max-width: 970px){
        nav{
            ul{
                padding-top: 8px; 
                padding-bottom: 8px;

                a{
                    font-size: 0.9rem;
                }
            }
        }
    }

    @media only screen and (max-width: 550px){
        nav{
            ul{
                padding-top: 8px; 
                padding-bottom: 8px;
                

                a{
                    font-size: 0.9rem;
                    font-size: 0.82rem;
                    letter-spacing: 0;
                }

                li{
                    margin-bottom: 2px;
                }
            }
        }
    }
    
</style>