<template>
    <div class="form-control">
        <label for="">{{label}} </label>
        <textarea 
            :name="name" 
            :placeholder="placeholder" 
            v-model.lazy="inputValue" 
            @change="emitValue" 
            rows="7"
            />
    </div>
</template>

<script>
export default {
    props: ["label", "name", "placeholder", "rows"],
    data(){
        return{
            inputValue: ''
        }
    }, 
    methods: {
        // emitir evento atualizando o estado do componente pai (general information)
        // no componente pai, ao clicar no botão 'próximo', atualizar o estado global
        
        emitValue(){
            this.$emit('emit-input-text-value', this.inputValue, this.name)
        }
    }
}
</script>

<style scoped lang="scss">

    .form-control{
        display: flex;
        flex-direction: column;
        width: 85%;
        margin: 20px auto 40px auto;
    }

    label{
        color: #212c3a;
        font-size: 1rem;
        font-weight: 500;
        
    }

    textarea{
        border: 1px solid #c0cfe0;
        padding: 15px 20px;
        margin-top: 8px;
        border-radius: 8px;
        transition: .2s; 
        font-size: 1rem;
    }

    textarea:focus{
        outline: none; 
        transform: scale(1.02);
        box-shadow: 2px 2px 14px rgba(141, 180, 196, 0.411);
    }

    @media only screen and (max-width: 1400px){
        .form-control{
            
            margin: 20px auto 30px auto;

            text-area{
                padding: 10px; 
                font-size: 0.8rem;
            }
        }
    }
</style>